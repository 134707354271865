<template>
    <div id="ap">
        <a-layout>
            <div class="content-header">
                <a-affix :offset-top="0" style="width: 100%;">
                    <a-row type="flex" justify="space-between">
                        <a-col span="12">
                            <common-page-btn @save="doAdd()"></common-page-btn>
                        </a-col>
                        <a-col span="12">
                            <a-row type="flex" align="middle" justify="end" :gutter="3">
                                <a-col span="12">
                                    <a-input v-model="param.keyword" allowClear search @pressEnter="getList"
                                             @on-clear="getList" placeholder="姓名或者账号名称查询"></a-input>
                                </a-col>
<!--                                <a-col>-->
<!--                                    <a-button shape="circle" type="primary" @click="getList" ghost icon="ios-search">Search</a-button>-->
<!--                                </a-col>-->
                            </a-row>
                        </a-col>
                    </a-row>
                </a-affix>

            </div>
        </a-layout>

        <a-layout>
            <a-layout-content>
                <a-table  :scroll="{x:'100%'}" :pagination="false" :style="{background:'#FFF'}" size="small" bordered :columns="keys" rowKey="admId" ref="list" :loading="loading" :data-source="data.records">
                    <!--状态-->
                    <template slot-scope="row" slot="status">
                        <template v-if="row.status==1">
                            <a-icon type="check-circle" style="color: #090" />
                        </template>
                        <template v-else>
                            <a-icon type="stop"/>
                        </template>
                    </template>
                    <!--操作-->
                    <template slot-scope="row" slot="action">
                        <a-button-group shape="circle" size="small" v-if="row.admId>1">
                            <a-tooltip title="修改" v-has="'admin:save'"><a-button shape="circle" type="link" icon="edit" @click="doAdd(row)"></a-button></a-tooltip>
                            <a-tooltip title="删除" v-has="'admin:del'"><a-button shape="circle" type="link" icon="delete" @click="del(row)"></a-button></a-tooltip>
                        </a-button-group>
                        <span v-else>无操作权限</span>
                    </template>
                </a-table>

            </a-layout-content>
        </a-layout>
        <a-row>
            <a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
                <a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext"
                      @showSizeChange="setLimit" :current="data.current" />
            </a-col>
        </a-row>
        <a-back-top />
        <add-admin ref="addAdmin" :appId="param.appId" @success="getList"></add-admin>
    </div>
</template>

<script>
    import addAdmin from "./addAdmin";
    export default {
        components:{addAdmin},
        data() {
            return {
                loading: false,
                data: {},
                param: {
                    page: 1,
                    limit: 20,
                    keyword: ''
                },
                keys: [
                    {title: 'ID', dataIndex: "admId", width: 100, align: 'center'},
                    {title: "姓名", dataIndex: "name", align: 'left'},
                    {title: "登录账号", dataIndex: "username", align: 'center',width: 260},
                    {title: "身份", dataIndex: "roleName", align: 'center',width: 260},
                    {title: "状态", scopedSlots: { customRender: 'status' }, align: 'center',width: 100},
                    {title: "操作", scopedSlots: { customRender: 'action' }, align: "center",width: 160}
                ]
            }
        },
        created() {
            this.getList();
        },
        methods: {
            doAdd(row){
                this.$refs.addAdmin._show(row ? row : null);
            },
            del(row){
                this.utils.confirm("您确认要删除此管理员吗?").then(()=>{
                    this.http.delete("/platform/admin/del/"+row.admId).then(ret=>{
                        this.utils.success("删除成功！").then(()=>{
                            this.getList();
                        });
                    })
                })
            },
            // 获取数据
            getList() {
                this.utils.loading();
                this.http.get('/platform/admin/list', this.param).then(ret => {
                    this.data = ret.data;
                    this.utils.closeAll()
                }).catch(err => {
                    this.utils.closeAll()
                    console.log(err)
                })
            },
            //设置每页的显示条数
            setLimit(current,limit) {
                this.param.page = 1;
                this.param.limit = limit;
                this.getList();
            },
            //页码切换
            getNext(page) {
                this.param.page = page;
                this.getList();
            },
        }
    }
</script>

<style scoped>

</style>
